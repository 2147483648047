import { createRouter, createWebHistory } from 'vue-router';
import MainPage from "./components/MainPage.vue";
import FirstLevel from "./components/FirstLevel.vue";
import AddDoc from "./components/AddDoc.vue";

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', name: 'MainPage', component: MainPage, props: true},
        {path: '/firstlevel', name: 'FirstLevel', component: FirstLevel, props: true},
        {path: '/addDoc', name: 'AddDoc', component: AddDoc, props: true},
    ]
});
