import { createStore } from 'vuex';

const appStore = createStore({
    state() {
        return {
            actualLevel:[],
            secondLevel:[]
        };
    },
    mutations: {
        setActualLevel(state, level) {
            state.actualLevel = level;
        },
        setSecondLevel(state, level) {
            state.secondLevel = level;
        },
    },
    actions: {

    }
});


export default appStore;