<template>
    <div>
        <h3>{{ this.$store.state.secondLevel.name }}</h3>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style scoped>
</style>