<template>
  <div class="steps-page-container">
    <div class="notebook-leftsidemenu-middlestages">
        <!--MIDDLE STAGES-->
        <p class="error-save-note">{{ errorSaveStage }}{{ errorUploadSteps }}{{ errorNoSteps }}{{ errorDeleteStep }}</p>
        <div class="middle-steps-container">
          <div v-for="step in constructionSteps" :key="step.name" class="middle-steps-element">
            <img @click="editStepNameAndDescription(step.objectId)" class="edit-icon" :src="images.edit" alt=""/>
            <img @click="removeStep(step.objectId)" class="delete-icon" :src="images.bin" alt=""/>
              <div @click="openFirstLevel(step)" class="name-description">
                <h3>{{ step.name }}</h3>
                <p>{{ step.description }}</p>
              </div>
          </div>
          <div @click="addNewStep" class="middle-steps-element add-new">
            <p>+</p>
          </div>
        <!--EDIT STEP BOX-->
          <div v-if="editStepBoxIsVisible">
            <div class="editStep-background"></div>
            <div class="editStep-box">
              <h4>Zmień nazwę etapu budowy:</h4>
              <div>
                <label>Nowa nazwa etapu budowy</label>
                <input type="text" v-model="editedStepName" name="editedStepName"/>
              </div>
              <div>
                <label>Nowy opis: (opcjonalnie)</label>
                <input type="text" v-model="editedStepDescription" name="editedStepDescription"/>
              </div>
              <div class="buttons-container">
                <button @click="cancelChangeNewStep" class="button-save-new-step">Anuluj</button>
                <button @click="saveEditedExistingStep" class="button-save-new-step">Zapisz</button>
              </div>
            </div>
          </div>
          <!--END EDIT STEP BOX-->
          <!--NEW STEP BOX-->
          <div v-if="addNewStepBoxIsVisible">
            <div class="newStep-background"></div>
            <div class="newStep-box">
              <h4>Dodaj nowy etap budowy</h4>
              <div>
                <label>Twoja nazwa etapu budowy</label>
                <input type="text" v-model="newStepName" name="newStepName"/>
              </div>
              <div>
                <label>Opis: (opcjonalnie)</label>
                <input type="text" v-model="newStepDescription" name="newStepDescription"/>
              </div>
              <div class="buttons-container">
                <button @click="cancelNewStep" class="button-save-new-step">Anuluj</button>
                <button @click="saveNewStep" class="button-save-new-step">Zapisz</button>
              </div>
            </div>
          </div>
          <!--END NEW STEP BOX-->
          <!--CONFIRM DELETE STEP-->
          <div v-if="confirmDeleteBoxStepIsVisble">
            <div class="newNote-background"></div>
            <div class="newNote-box">
              <h4>Czy na pewno chcesz usunąć etap?</h4>
              <div class="buttons-container">
                <button @click="notDeleteStep" class="button-save-new-step">Anuluj</button>
                <button @click="confirmDeleteStep" class="button-save-new-step">Usuń</button>
              </div>
            </div>
          </div>
        <!--END CONFIRM DELETE STEP-->
        </div>
        <!--END MIDDLE STAGES-->
      </div>
    </div>
    <!--RIGHT SIDE MENU-->
</template>
<script>
import edit from '../photos/edit.png';
import bin from '../photos/bin.png';
import axios from 'axios';

export default {
  name: 'MainPage',
  data() {
    return {
      constructionSteps: [],
      addNewStepBoxIsVisible: false,
      editStepBoxIsVisible: false,
      confirmDeleteBoxStepIsVisble: false,
      newStepName: "",
      newStepDescription: "",
      editedStepName: "",
      editedStepDescription: "",
      editedStepId: "",
      errorUploadSteps: "",
      errorSaveStage: "",
      errorNoSteps: "",
      errorDeleteStep: "",
      removeStepId: "",
      date: null,
      selectedColor: 'green',
      images: {
        edit: edit,
        bin: bin
      }
    }
  },
  created() {
    this.fetchSteps();
  },
  methods: {
    fetchSteps() {
      axios.get(`https://muchobottle-eu.backendless.app/api/data/stage?where=%60ownerId%60%20IS%20NULL`).then(response => {
            if (response.status === 200) {
              this.constructionSteps = response.data;
              if(this.notes == "") {
                this.errorNoSteps = "Nie masz utworzonych żadnych etapów budowy.";
              } else {
                this.constructionSteps = response.data;
                this.errorNoSteps = "";
              }
            }
        })
        .catch(error => {
                console.log(error);
                this.errorUploadSteps = "Nie udało się załadować etapów. Sprawdź połączenie internetowe. Kod błędu: " + error;
                console.log(this.errorUploadSteps);
              });
    },
    addNewStep() {
      this.addNewStepBoxIsVisible = true;
    },
    saveNewStep() {
      var newStep = {
        name: this.newStepName,
        description: this.newStepDescription,
      };
        axios.put(`https://muchobottle-eu.backendless.app/api/data/stage`, newStep).then(response => {
            if (response.status === 200) {
              this.fetchSteps();
            }
        })
            .catch(error => {
              console.log(error);
                this.errorSaveStage = "Nie udało się edytować notatki. Sprawdź połączenie internetowe. kod błędu: " + error;
                setTimeout(() => {
                    this.errorSavestage = "";
                }, "5000");
            });
      this.newStep = "";
      this.addNewStepBoxIsVisible = false;
    },
    editStepNameAndDescription(id) {
      var editedStep = this.constructionSteps.find((step)=> step.objectId == id);
      this.editedStepName = editedStep.name;
      this.editedStepDescription = editedStep.description;
      this.editedStepId = id;
      this.editStepBoxIsVisible = true;
    },
    saveEditedExistingStep() {
        var editedStep = {
        objectId: this.editedStepId,
        name: this.editedStepName,
        description: this.editedStepDescription
      };
       axios.put(`https://muchobottle-eu.backendless.app/api/data/stage/${this.editedStepId}`, editedStep).then(response => {
            if (response.status === 200) {
              this.fetchSteps();
            }
        })
            .catch(error => {
              console.log(error);
                this.errorSaveNote = "Nie udało się edytować etapu. Sprawdź połączenie internetowe. kod błędu: " + error;
                setTimeout(() => {
                    this.errorSaveNote = "";
                }, "5000");
            });
       this.editedNote = "";
       this.editedNoteId = "";
       this.editNoteBoxIsVisible = false;
      this.editStepBoxIsVisible = false;
    },
    cancelNewStep() {
      this.addNewStepBoxIsVisible = false;
    },
    cancelChangeNewStep() {
      this.editStepBoxIsVisible = false;
    },
    removeStep(id) {
      this.confirmDeleteBoxStepIsVisble = true;
      this.removeStepId = id;
    },
    confirmDeleteStep() {
        axios.delete(`https://muchobottle-eu.backendless.app/api/data/stage/${this.removeStepId}`).then(response => {
            if (response.status === 200) {
              this.fetchSteps();
            }
        })
            .catch(error => {
              console.log(error);
                this.errorDeleteStep = "Nie udało się usunąć etapu. Sprawdź połączenie internetowe. kod błędu: " + error;
                setTimeout(() => {
                    this.errorDeleteStep = "";
                }, "5000");
            });
        this.confirmDeleteBoxStepIsVisble = false;
    },
    notDeleteStep() {
         this.confirmDeleteBoxStepIsVisble = false;
    },
    openFirstLevel(step) {
      this.$store.commit('setActualLevel', step);
      this.$router.push({ name: 'FirstLevel'});
    }
  }
}
</script>

<style scoped>
*, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.steps-page-container {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

/*END NOTEBOOK STYLE*/

.active {
  background-color: #FAC796;
}
/*END LEFT SIDE MENU STYLE*/
/*MIDDLE STEP STYLE*/
.middle-steps-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 50px;
  margin: 50px;
}
.middle-steps-element {
  width: 200px;
  height: 150px;
  border: 1px solid #91756A;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.middle-steps-element h3 {
  padding: 0;
  margin: 0;
}
.edit-icon {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.delete-icon {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 30px;
}
.edit-icon:hover {
  cursor: pointer;
}
.edit-note-icon {
  width: 20px;
}
.edit-note-icon:hover {
  cursor: pointer;
}
.middle-steps-element p{
  font-size: 13px;
}
.add-new:hover {
  cursor: pointer;
}
.add-new p{
  font-size: 55px;
  font-weight: 200;
  transition:0.2s ease-in-out;
}
.add-new:hover p {
  transform: scale(1.3);
}
.name-description:hover {
  cursor: pointer;
}
/*END MIDDLE STEP STYLE*/
/*NEW STEP STYLE*/
.newStep-background, .editStep-background, .newNote-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.newStep-box, .editStep-box, .newNote-box {
  width: 50%;
  height: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20%; /* Negative half of height. */
  margin-left: -25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 20px;
  font-weight: 500;
}
.newStep-box h4, .editStep-box h4 {
  font-size: 25px;
}
.newStep-box div, .editStep-box div {
  margin: 20px;
}
.newStep-box input, .editStep-box input {
  margin-left: 10px;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
button {
  padding: 10px 40px;
  margin: 0 20px;
  background-color: #FABBB5;
  border: none;
  border-radius: 20px;
}
button:hover {
  cursor: pointer;
  background-color:#F59898;
}
/*END NEW STEP STYLE*/
/*RIGHT SIDE MENU STYLE*/
.right-side-menu {
  width: 20%;
  float: right;
}
.date-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 21px;
  margin: 5px;
}
.expenses-summary {
  width: 230px;
  border: 2px solid #F59898;
  margin: 0 5px 5px 5px;
}
.expense-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
}
.amount-net {
  color: #F59898;
  font-weight: bold;
}
.amount-vat {
  color: #FABBB5;
  font-weight: bold;
}
.amount-gross {
  color: #fc6f6f;
  font-weight: bold;
}
.budget-summary {
  width: 230px;
  border: 2px solid #3f3f3f;
  margin: 5px;
}
.amount-budget {
  color: #3f3f3f;
  font-weight: bold;
}
.summary {
  width: 230px;
  border: 2px solid green;
  margin: 5px;
}
.amount-summary {
  color: green;
  font-weight: bold;
}
/* CLOCK STYLE*/
.clock-container {
  position: static;
  background: #fff url(../photos/clock/ios_clock.svg) no-repeat center;
  background-size: 170px;
  padding: 0;
  height: 220px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clock {
  width: 101px;
  height: 101px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  transform: rotate(180deg) scale(2);
}
.clock .hours {
  border: 2px solid black;
  position: absolute;
  height: 30%;
  border-radius: 2px;
  left: 48px;
  top: 50px;
  animation-duration: 43200s;
}
.clock .minutes {
  border: 1px solid black;
  position: absolute;
  top: 50px;
  left: 50px;
  height: 45%;
  animation-duration: 3600s;
}
.clock .seconds {
  border: 1px solid red;
  position: absolute;
  top: 50px;
  left: 50px;
  height: 45%;
  animation-duration: 60s;
}
.animate {
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.hand {
  transform-origin: top center;
}
.delete-icon {
  cursor: pointer;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*END CLOCK STYLE*/
/*CALENDAR STYLE*/
.calendar {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/*END CALENDAR STYLE*/
@media only screen and (max-width: 800px) {
  .main-page-container {
    width: 100%;
  }
  .notebook-leftsidemenu-middlestages {
    width: 100%;
    flex-direction: column;
  }
  .notebook {
    width: 100%;
    overflow: scroll;
  }
  .left-side-menu-middle-stages {
        flex-direction: column;
    width: 100%;
  }
  .right-side-menu {
    width: 20%;
  }
}
</style>
