<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
  </metainfo>
  <TheHeaders/>
</template>
<script>
import TheHeaders from './components/TheHeaders.vue';
import { useMeta } from 'vue-meta'
export default {
  name: 'App',
    setup () {
    useMeta({
      title: 'EDB',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  //   metaInfo() {
  //   return {
  //     title: "EDB",
  //     metaInfo: [
  //       {
  //         vmid: "facebook-domain-verification",
  //         name: "facebook-domain-verification",
  //         content:
  //           "c2dak5gv8v5q1o3jqthgjthy76ghha8sh444",
  //       },
  //     ],
  //   };
  // },
  //   metaInfo: {
  //   title: 'EDB',
  //   titleTemplate: '%s | vue-meta Example App',
  //   meta: [
  //     { charset: 'utf-8' },
  //     { name: 'facebook-domain-verification', content: 'c2dak5gv8v5q1o3jqthgjthy76ghha8sh444' },
  //     { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  //   ]
  // },
  components: {
    TheHeaders
  }
}
</script>

<style>
*, body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
