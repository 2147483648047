<template>
  <div class="main-page-container">
      <!--NOTEBOOK-->
      <div class="notebook">
        <p class="error-save-note">{{ errorSaveNote }}</p>
        <!--NEW NOTE BOX-->
          <div v-if="addNewNoteBoxIsVisible">
            <div class="newNote-background"></div>
            <div class="newNote-box">
              <h4>Nowa notatka</h4>
              <div class="title-content">
                <div class="newnote-header-input">
                  <h5>Tytuł (opcjonalnie)</h5>
                  <input type="text" v-model="newNoteTitle"/>
                </div>
                <div class="newnote-header-input">
                  <h5>Treść notatki</h5>
                  <textarea v-model="newNote" placeholder="dodaj notatkę"></textarea>
                </div>
              </div>
              <div class="buttons-container">
                <button @click="cancelAddNote" class="button-save-new-step">Anuluj</button>
                <button @click="addNote" class="button-save-new-step">Zapisz</button>
              </div>
            </div>
          </div>
          <!--END NEW NOTE BOX-->
          <!--EDIT NOTE BOX-->
          <div v-if="editNoteBoxIsVisible">
            <div class="newNote-background"></div>
            <div class="newNote-box">
              <h4>Edytuj notatkę</h4>
              <div class="title-content">
                <div class="newnote-header-input">
                  <h5>Tytuł</h5>
                  <input type="text" v-model="editedNoteTitle"/>
                </div>
                <div class="newnote-header-input">
                  <h5>Treść notatki</h5>
                  <textarea v-model="editedNote"></textarea>
                </div>
              </div>
              <div class="buttons-container">
                <button @click="cancelEditNote" class="button-save-new-step">Anuluj</button>
                <button @click="saveEditedNote" class="button-save-new-step">Zapisz</button>
              </div>
            </div>
          </div>
          <!--END EDIT NOTE BOX-->
          <!--CONFIRM DELETE NOTE-->
          <div v-if="confirmDeleteBoxIsVisble">
            <div class="newNote-background"></div>
            <div class="newNote-box">
              <h4>Czy na pewno chcesz usunąć notatkę?</h4>
              <div class="buttons-container">
                <button @click="notDelete" class="button-save-new-step">Anuluj</button>
                <button @click="confirmDelete" class="button-save-new-step">Usuń</button>
              </div>
            </div>
          </div>
        <!--END CONFIRM DELETE NOTE-->
        <!--SAVED NOTES CONTAINER-->
        <div class="saved-notes-container">
          <button @click="openAddNewNoteBox" class="new-note-button">Dodaj notatkę</button>
          <div class="saved-notes">
            <p class="error-upload-notes">{{ errorUploadNotes }}</p>
            <p class="error-no-notes">{{ errorNoNotes }}</p>
            <table>
              <tr v-for="note in notes" :key="note.id">
                <td><img @click="editNote(note.objectId)" class="edit-note-icon" :src="images.edit" alt=""/></td>
                <td><img @click="removeNote(note.objectId)" class="edit-note-icon" :src="images.bin" alt=""/></td>
                <td>
                  <p>{{note.title}} <span @click="showHideNoteContent(note.objectId)" class="triangle">{{ showNoteContentId == note.objectId ? "▲" : "▼" }}</span></p>
                  <p v-if="showNoteContentId == note.objectId">{{note.content}}</p>
                </td>
              </tr>
            </table>
          </div>
      </div>
      <!--END NOTEBOOK-->
    </div>
    <!--END NOTEBOOK AND LEFT SIDE MENU-->
    <!--RIGHT SIDE MENU-->
    <div class="right-side-menu">
      <div class="clock-container">
        <div class="clock">
          <span class="hand seconds animate"></span>
          <span class="hand minutes animate"></span>
          <span class="hand hours animate"></span>
        </div>
      </div>
      <div class="date-time">
        <p>{{ todayDate }}</p>
      </div>
      <div class="calendar">
        <!--<VCalendar />-->
        <VDatePicker v-model="date" :color="selectedColor" show-weeknumbers/>
      </div>
      <div class="expenses-summary">
        <div class="expense-container">
          <p>Wydatki netto:</p>
          <p class="amount-net">50 000</p>
        </div>
        <div class="expense-container">
          <p>VAT:</p>
          <p class="amount-vat">11 500</p>
        </div>
        <div class="expense-container">
          <p>Wydatki brutto::</p>
          <p class="amount-gross">61 500</p>
        </div>
      </div>
      <div class="budget-summary">
        <div class="expense-container">
          <p>Założony budżet:</p>
          <p class="amount-budget">1 000 000</p>
        </div>
      </div>
      <div class="summary">
        <div class="expense-container">
          <p>Podsumowanie:</p>
          <p class="amount-summary">938 500</p>
        </div>
      </div>
    </div>
    <!--LEFT SIDE MENU-->
    <div class="left-side-menu">
      <div v-for="step in constructionSteps" :key="step.name"  @click="openFirstLevel(step)" class="left-side-menu-element">
        {{ step.name }}
      </div>
    </div>
    <!--END LEFT SIDE MENU-->
    <div>
      <router-view/>
    </div>
  </div>
</template>
<script>
import edit from '../photos/edit.png'
import bin from '../photos/bin.png'
import axios from 'axios';

export default {
  name: 'MainPage',
  data() {
    return {
      notes: [],
      constructionSteps: [],
      addNewNoteBoxIsVisible: false,
      editNoteBoxIsVisible: false,
      confirmDeleteBoxIsVisble: false,
      newNote: "",
      newNoteTitle: "",
      showNoteContentId: "",
      editedNoteTitle:"",
      editedNote: "",
      editedNoteId: "",
      errorUploadNotes: "",
      errorNoNotes:"",
      errorSaveNote: "",
      removeNoteId: "",
      date: null,
      selectedColor: 'green',
      images: {
        edit: edit,
        bin: bin
      }
    }
  },
  computed: {
      todayDate() {
        var date = new Date();
        var year = date.getFullYear();
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return day + '/' + month + '/' + year;
      }
  },
  created() {
    this.fetchNotes();
    this.fetchSteps();
  },
  mounted() {
      //CLOCK
      var secondsHand = document.querySelector('.seconds');
      var minutesHand = document.querySelector('.minutes');
      var hoursHand = document.querySelector('.hours');
      var now = new Date();
      console.log(secondsHand);

        var secs = now.getSeconds(),
            mins = now.getMinutes() * 60,
            hours = now.getHours() * 3600;

        secondsHand.style.animationDelay = '-' + secs + 's';
        minutesHand.style.animationDelay = '-' + mins + 's';
        hoursHand.style.animationDelay = '-' + hours + 's';
        //END CLOCK
  },
  methods: {
    fetchNotes() {
      axios.get(`https://muchobottle-eu.backendless.app/api/data/notes?sortBy=created%20desc`).then(response => {
            if (response.status === 200) {
              this.notes = response.data;
              if(this.notes == "") {
                this.errorNoNotes = "brak notatek";
              } else {
                this.notes = response.data;
                this.errorNoNotes = "";
              }
            }
        })
        .catch(error => {
                console.log(error);
                this.errorUploadNotes = "Nie udało się załadować notatek. Sprawdź połączenie internetowe. Kod błędu: " + error;
              });
    },
    fetchSteps() {
      axios.get(`https://muchobottle-eu.backendless.app/api/data/stage?where=%60ownerId%60%20IS%20NULL`).then(response => {
            if (response.status === 200) {
              this.constructionSteps = response.data;
              if(this.notes == "") {
                this.errorNoSteps = "Nie masz utworzonych żadnych etapów budowy.";
              } else {
                this.constructionSteps = response.data;
                this.errorNoSteps = "";
              }
            }
        })
        .catch(error => {
                console.log(error);
                this.errorUploadSteps = "Nie udało się załadować etapów. Sprawdź połączenie internetowe. Kod błędu: " + error;
                console.log(this.errorUploadSteps);
              });
    },
    showHideNoteContent(id) {
      if(this.showNoteContentId == "") {
        this.showNoteContentId = id;
      } else {
        this.showNoteContentId = "";
      }
    },
    openAddNewNoteBox() {
      this.addNewNoteBoxIsVisible = true;
    },
    addNote() {
      var newNote = {
        ownerId: Date.now(),
        content: this.newNote,
        title: this.newNoteTitle
      };
        axios.put(`https://muchobottle-eu.backendless.app/api/data/notes`, newNote).then(response => {
            if (response.status === 200) {
              this.fetchNotes();
            }
        })
            .catch(error => {
              console.log(error);
                this.errorSaveNote = "Nie udało się zapisać notatki. Sprawdź połączenie internetowe. Kod błędu: " + error;
                setTimeout(() => {
                    this.errorSaveNote = "";
                }, "5000");
            });
      this.newNote = "";
      this.newNoteTitle = "";
      this.addNewNoteBoxIsVisible = false;
    },
    cancelAddNote() {
      this.addNewNoteBoxIsVisible = false;
      this.newNote = "";
    },
    editNote(id) {
      var editedNote = this.notes.find((note)=> note.objectId == id);
      this.editedNote = editedNote.content;
      this.editedNoteTitle = editedNote.title;
      this.editedNoteId = id;
      this.editNoteBoxIsVisible = true;
    },
    cancelEditNote() {
      this.newNote = "";
      this.editNoteBoxIsVisible = false;
    },
    saveEditedNote() {
      var editedNote = {
        objectId: this.editedNoteId,
        content: this.editedNote,
        title: this.editedNoteTitle
      };
       axios.put(`https://muchobottle-eu.backendless.app/api/data/notes/${this.editedNoteId}`, editedNote).then(response => {
            if (response.status === 200) {
              this.fetchNotes();
            }
        })
            .catch(error => {
              console.log(error);
                this.errorSaveNote = "Nie udało się edytować notatki. Sprawdź połączenie internetowe. kod błędu: " + error;
                setTimeout(() => {
                    this.errorSaveNote = "";
                }, "5000");
            });
       this.editedNote = "";
       this.editedNoteId = "";
       this.editNoteBoxIsVisible = false;
    },
    removeNote(id) {
      this.confirmDeleteBoxIsVisble = true;
      this.removeNoteId = id;
    },
    confirmDelete() {
        axios.delete(`https://muchobottle-eu.backendless.app/api/data/notes/${this.removeNoteId}`).then(response => {
            if (response.status === 200) {
              this.fetchNotes();
            }
        })
            .catch(error => {
              console.log(error);
                this.errorSaveNote = "Nie udało się usunąć notatki. Sprawdź połączenie internetowe. kod błędu: " + error;
                setTimeout(() => {
                    this.errorSaveNote = "";
                }, "5000");
            });
        this.confirmDeleteBoxIsVisble = false;
    },
    notDelete() {
      this.indexToRemove = "";
      this.confirmDeleteBoxIsVisble = false;
    },
    openFirstLevel(step) {
      console.log(step);
      this.$store.commit('setActualLevel', step);
      this.$router.push({ name: 'FirstLevel'});
    }
  }
}
</script>

<style scoped>
*, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main-page-container {
  margin: 20px;
  display: grid;
  grid-template-columns: 20% auto 20%;
  grid-template-rows: auto auto;
    grid-template-areas:
    'notebook notebook rightMenu'
    'leftMenu . rightMenu'
}
.right-side-menu {
  grid-area: rightMenu;
}
.notebook {
  grid-area: notebook;
}
.left-side-menu {
 grid-area: leftMenu;
}
.notebook-leftside-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*NOTEBOOK STYLE*/

.saved-notes-container {
  width: 100%;
}
.new-note-button {
  width: 100%;
  margin:0;
  border-radius: 0;
}
.triangle:hover {
  cursor: pointer;
}
.title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-content h5 {
  font-size: 14px;
  margin-top: 20px;
  font-weight: 400;
}
.title-content input {
  width: 400px;
  padding: 5px;
  height: 25px;
}
.newnote-header-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}
.newNote-box textarea {
  width: 100%;
  height: 150px;
  width: 400px;
  resize: none;
  padding: 10px;
}
.newNote-box textarea:focus {
  outline: none;
}
.newNote-box button {
  font-size: 15px;
  align-self: flex-start;
  width: 180px;
  margin:10px;
}
h3 {
  margin: 40px 0 0;
}
.saved-notes-container {
  width: 100%;
}
.saved-notes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  overflow-y: scroll;
  max-height: 170px;
  width: 100%;
}
.saved-notes table {
  padding: 10px;
}
table {
  width: 100%;
  border-bottom: 1px solid #FABBB5;
  padding: 10px;
  border-collapse: collapse;
}
tr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #FABBB5;
  padding: 3px;
}
tr p {
  margin: 5px;
}
.saved-notes table td{
  float: left;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
a {
  color: #42b983;
}
.error-save-note {
  margin: 5px;
  color: #B71C1C;
}
.error-upload-notes {
  margin: 5px;
  color: #B71C1C;
}
.error-no-notes {
  color: gray;
}
/*END NOTEBOOK STYLE*/
/*LEFT SIDE MENU STYLE*/
.left-side-menu-element {
  padding: 20px;
  margin: 0;
  border: 1px solid #FAC796;
  text-transform: uppercase;
  transition: 0.3s;
}
.left-side-menu-element:hover {
  background-color: #FABBB5;
  cursor: pointer;
}
.active {
  background-color: #FAC796;
}
/*END LEFT SIDE MENU STYLE*/
/*MIDDLE STEP STYLE*/
.middle-steps-element {
  width: 200px;
  height: 150px;
  border: 1px solid #91756A;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.middle-steps-element h3 {
  padding: 0;
  margin: 0;
}
.edit-icon {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.delete-icon {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 30px;
}
.edit-icon:hover {
  cursor: pointer;
}
.edit-note-icon {
  width: 20px;
}
.edit-note-icon:hover {
  cursor: pointer;
}
.middle-steps-element p{
  font-size: 13px;
}
.add-new:hover {
  cursor: pointer;
}
.add-new p{
  font-size: 55px;
  font-weight: 200;
  transition:0.2s ease-in-out;
}
.add-new:hover p {
  transform: scale(1.3);
}
/*END MIDDLE STEP STYLE*/
/*NEW STEP STYLE*/
.newStep-background, .editStep-background, .newNote-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.newStep-box, .editStep-box, .newNote-box {
  width: 50%;
  height: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20%; /* Negative half of height. */
  margin-left: -25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 20px;
  font-weight: 500;
}
.newStep-box h4, .editStep-box h4 {
  font-size: 25px;
}
.newStep-box div, .editStep-box div {
  margin: 20px;
}
.newStep-box input, .editStep-box input {
  margin-left: 10px;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
button {
  padding: 10px 40px;
  margin: 0 20px;
  background-color: #FABBB5;
  border: none;
  border-radius: 20px;
}
button:hover {
  cursor: pointer;
  background-color:#F59898;
}
/*END NEW STEP STYLE*/
/*RIGHT SIDE MENU STYLE*/
.right-side-menu {
  width: 20%;
  float: right;
}
.date-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 21px;
  margin: 5px;
}
.expenses-summary {
  width: 230px;
  border: 2px solid #F59898;
  margin: 0 5px 5px 5px;
}
.expense-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
}
.amount-net {
  color: #F59898;
  font-weight: bold;
}
.amount-vat {
  color: #FABBB5;
  font-weight: bold;
}
.amount-gross {
  color: #fc6f6f;
  font-weight: bold;
}
.budget-summary {
  width: 230px;
  border: 2px solid #3f3f3f;
  margin: 5px;
}
.amount-budget {
  color: #3f3f3f;
  font-weight: bold;
}
.summary {
  width: 230px;
  border: 2px solid green;
  margin: 5px;
}
.amount-summary {
  color: green;
  font-weight: bold;
}
/* CLOCK STYLE*/
.clock-container {
  position: static;
  background: #fff url(../photos/clock/ios_clock.svg) no-repeat center;
  background-size: 170px;
  padding: 0;
  height: 220px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clock {
  width: 101px;
  height: 101px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  transform: rotate(180deg) scale(2);
}
.clock .hours {
  border: 2px solid black;
  position: absolute;
  height: 30%;
  border-radius: 2px;
  left: 48px;
  top: 50px;
  animation-duration: 43200s;
}
.clock .minutes {
  border: 1px solid black;
  position: absolute;
  top: 50px;
  left: 50px;
  height: 45%;
  animation-duration: 3600s;
}
.clock .seconds {
  border: 1px solid red;
  position: absolute;
  top: 50px;
  left: 50px;
  height: 45%;
  animation-duration: 60s;
}
.animate {
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.hand {
  transform-origin: top center;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*END CLOCK STYLE*/
/*CALENDAR STYLE*/
.calendar {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/*END CALENDAR STYLE*/
@media only screen and (max-width: 800px) {
  .main-page-container {
    width: 100%;
  }
  .notebook-leftsidemenu-middlestages {
    width: 100%;
    flex-direction: column;
  }
  .notebook {
    width: 100%;
    overflow: scroll;
  }
  .left-side-menu-middle-stages {
        flex-direction: column;
    width: 100%;
  }
  .right-side-menu {
    width: 20%;
  }
}
</style>
