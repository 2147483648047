import { createApp } from 'vue'
import { router  } from './routes.js'
import App from './App.vue'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import store from './store/index.js';
//import VueMeta from 'vue-meta';
import { createMetaManager } from 'vue-meta'

createApp(App).use(createMetaManager()).use(VCalendar, {}).use(router).use(store).mount('#app')
