<template>
    <div class="firstlevel-container">
        <h3>{{ this.$store.state.actualLevel.name }}</h3>
        <p class="small">{{ this.$store.state.actualLevel.description }}</p>
        <div class="middle-steps-container">
          <div v-for="step in firstLevelSteps" :key="step.name" class="middle-steps-element">
            <img @click="editStepNameAndDescription(step.objectId)" class="edit-icon" :src="images.edit" alt=""/>
            <img @click="removeStep(step.objectId)" class="delete-icon" :src="images.bin" alt=""/>
              <div @click="openAddDocLevel(step)" >
                <h3>{{ step.name }}</h3>
                <p>{{ step.description }}</p>
              </div>
          </div>
          <div @click="addNewStep" class="middle-steps-element add-new">
            <p>+</p>
          </div>
        </div>
        <!--NEW STEP BOX-->
          <div v-if="addNewStepBoxIsVisible">
            <div class="newStep-background"></div>
              <div class="newStep-box">
                <div class="path">{{ this.$store.state.actualLevel.name }}</div>
                <div>
                <h4>Dodaj nowy etap budowy</h4>
                  <div>
                    <label>Twoja nazwa etapu budowy</label>
                    <input type="text" v-model="newStepName" name="newStepName"/>
                  </div>
                <div>
                  <label>Opis: (opcjonalnie)</label>
                  <input type="text" v-model="newStepDescription" name="newStepDescription"/>
                </div>
                <div class="buttons-container">
                  <button @click="cancelNewStep" class="button-save-new-step">Anuluj</button>
                  <button @click="saveNewStep" class="button-save-new-step">Zapisz</button>
                </div>
              </div>
            </div>
          </div>
          <!--END NEW STEP BOX-->
          <!--EDIT STEP BOX-->
          <div v-if="editStepBoxIsVisible">
            <div class="editStep-background"></div>
            <div class="editStep-box">
              <h4>Zmień nazwę etapu budowy:</h4>
              <div>
                <label>Nowa nazwa etapu budowy</label>
                <input type="text" v-model="editedStepName" name="editedStepName"/>
              </div>
              <div>
                <label>Nowy opis: (opcjonalnie)</label>
                <input type="text" v-model="editedStepDescription" name="editedStepDescription"/>
              </div>
              <div class="buttons-container">
                <button @click="cancelChangeNewStep" class="button-save-new-step">Anuluj</button>
                <button @click="saveEditedExistingStep" class="button-save-new-step">Zapisz</button>
              </div>
            </div>
          </div>
          <!--END EDIT STEP BOX-->
          <!--CONFIRM DELETE STEP-->
          <div v-if="confirmDeleteBoxStepIsVisble">
            <div class="newNote-background"></div>
            <div class="newNote-box">
              <h4>Czy na pewno chcesz usunąć etap?</h4>
              <div class="buttons-container">
                <button @click="notDeleteStep" class="button-save-new-step">Anuluj</button>
                <button @click="confirmDeleteStep" class="button-save-new-step">Usuń</button>
              </div>
            </div>
          </div>
        <!--END CONFIRM DELETE STEP-->
    </div>
</template>
<script>
import axios from 'axios';
import edit from '../photos/edit.png';
import bin from '../photos/bin.png';
export default {
    data() {
        return {
            firstLevelSteps: [],
            newStepName: "",
            newStepDescription: "",
            editedStepName: "",
            editedStepDescription: "",
            editedStepId: "",
            removeStepId: "",
            images: {
                edit: edit,
                bin: bin
            },
            addNewStepBoxIsVisible: false,
            editStepBoxIsVisible: false,
            confirmDeleteBoxStepIsVisble: false
        }
    },
    created() {
      this.fetchSteps();
    },
    mounted() {
      this.fetchSteps();
    },
    computed() {

    },
    watch: {

    },
    methods: {
      fetchSteps() {
          var ownerId = this.$store.state.actualLevel.objectId;
          console.log(ownerId);
          axios.get(`https://muchobottle-eu.backendless.app/api/data/stage?where=%60ownerId%60%20%3D%20'${ownerId}'`).then(response => {
                if (response.status === 200) {
                  this.firstLevelSteps = response.data;
                  if(this.firstLevelSteps == "") {
                    this.errorNoSteps = "Nie masz utworzonych żadnych etapów budowy.";
                  } else {
                    this.firstLevelSteps = response.data;
                    this.errorNoSteps = "";
                  }
                }
            })
            .catch(error => {
                    console.log(error);
                    this.errorUploadSteps = "Nie udało się załadować etapów. Sprawdź połączenie internetowe. Kod błędu: " + error;
                    console.log(this.errorUploadSteps);
                  });
        },
      addNewStep() {
        this.addNewStepBoxIsVisible = true;
        },
      saveNewStep() {
        var newStep = {
            name: this.newStepName,
            description: this.newStepDescription,
            ownerId: this.$store.state.actualLevel.objectId
        };
        console.log(newStep);
            axios.put(`https://muchobottle-eu.backendless.app/api/data/stage`, newStep).then(response => {
                if (response.status === 200) {
                this.fetchSteps();
                }
            })
                .catch(error => {
                console.log(error);
                    this.errorSaveStage = "Nie udało się edytować notatki. Sprawdź połączenie internetowe. kod błędu: " + error;
                    setTimeout(() => {
                        this.errorSavestage = "";
                    }, "5000");
                });
          this.newStep = "";
          this.addNewStepBoxIsVisible = false;
        },
        cancelNewStep() {
          this.addNewStepBoxIsVisible = false;
          this.newStepName = "";
          this.newStepDescription = "";
        },
        editStepNameAndDescription(id) {
          var editedStep = this.firstLevelSteps.find((step)=> step.objectId == id);
          this.editedStepName = editedStep.name;
          this.editedStepDescription = editedStep.description;
          this.editedStepId = id;
          this.editStepBoxIsVisible = true;
        },
        saveEditedExistingStep() {
            var editedStep = {
            objectId: this.editedStepId,
            name: this.editedStepName,
            description: this.editedStepDescription
          };
          axios.put(`https://muchobottle-eu.backendless.app/api/data/stage/${this.editedStepId}`, editedStep).then(response => {
                if (response.status === 200) {
                  this.fetchSteps();
                }
            })
                .catch(error => {
                  console.log(error);
                    this.errorSaveNote = "Nie udało się edytować etapu. Sprawdź połączenie internetowe. kod błędu: " + error;
                    setTimeout(() => {
                        this.errorSaveNote = "";
                    }, "5000");
                });
          this.editedStep = "";
          this.editedStepId = "";
          this.editStepBoxIsVisible = false;
        },
      cancelChangeNewStep() {
        this.editStepBoxIsVisible = false;
    },
      removeStep(id) {
        this.confirmDeleteBoxStepIsVisble = true;
        this.removeStepId = id;
    },
    confirmDeleteStep() {
        axios.delete(`https://muchobottle-eu.backendless.app/api/data/stage/${this.removeStepId}`).then(response => {
            if (response.status === 200) {
              this.fetchSteps();
            }
        })
            .catch(error => {
              console.log(error);
                this.errorDeleteStep = "Nie udało się usunąć etapu. Sprawdź połączenie internetowe. kod błędu: " + error;
                setTimeout(() => {
                    this.errorDeleteStep = "";
                }, "5000");
            });
        this.confirmDeleteBoxStepIsVisble = false;
    },
    notDeleteStep() {
         this.confirmDeleteBoxStepIsVisble = false;
    },
    openAddDocLevel(step) {
      console.log(step);
      this.$store.commit('setSecondLevel', step);
      this.$router.push({ name: 'AddDoc'});
    }
    }
}

</script>
<style scoped>
h3 {
    font-size: 28px;
    margin-top: 20px;
}
.small {
    font-size: 15px;
}
/* STEPS CONTAINER STYLE*/
.middle-steps-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 50px;
  margin: 50px;
}
.middle-steps-element {
  width: 200px;
  height: 150px;
  border: 1px solid #91756A;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.middle-steps-element h3 {
  padding: 0;
  margin: 0;
}
.edit-icon {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.delete-icon {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 30px;
}
.edit-icon:hover {
  cursor: pointer;
}
.edit-note-icon {
  width: 20px;
}
.edit-note-icon:hover {
  cursor: pointer;
}
.middle-steps-element p{
  font-size: 13px;
}
.add-new:hover {
  cursor: pointer;
}
.add-new p{
  font-size: 55px;
  font-weight: 200;
  transition:0.2s ease-in-out;
}
.add-new:hover p {
  transform: scale(1.3);
}
/*END STEPS CONTAINER STYLE*/
/*NEW STEP STYLE*/
.newStep-background, .editStep-background, .newNote-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.newStep-box, .editStep-box, .newNote-box {
  width: 50%;
  height: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20%; /* Negative half of height. */
  margin-left: -25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 20px;
  font-weight: 500;
}
.newStep-box h4, .editStep-box h4 {
  font-size: 25px;
}
.newStep-box div, .editStep-box div {
  margin: 20px;
}
.newStep-box input, .editStep-box input {
  margin-left: 10px;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
button {
  padding: 10px 40px;
  margin: 0 20px;
  background-color: #FABBB5;
  border: none;
  border-radius: 20px;
}
button:hover {
  cursor: pointer;
  background-color:#F59898;
}
.delete-icon {
  cursor: pointer;
}
.path {
  align-self: flex-start;
  width: 20%;
  font-size: 15px;
  margin: 0;
}
/*END NEW STEP STYLE*/
</style>